<template>
    <b-colxx xxs="12" id="step-thanks" >
		<!--<b-card>-->
    	<section id="msgGracias">
    		<h1>¡Muchas gracias por su compra!</h1>
    		<hr>
    		<div class="row invoice">
                <!--<div class="col-12">-->
                    <div class="invoice-contents col-12" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0"
                        offset="0"
                        style="background-color:#ffffff; max-width:830px; font-family: Helvetica,Arial,sans-serif !important; position: relative;">
                        <table bgcolor="#ffffff" border="0" cellpadding="0" cellspacing="0"
                            class="tableStyles"
                            heigth="auto">

                            <tbody>
                                <tr>
                                    <td class="imgCell" align="left" valign="center">
                                        <img id="logoGracias" src="/static/img/logo-sole.svg">
                                    </td>
                                    <td class="imgCell" align="right" valign="center"
                                        >
                                        <p class="headerInvoice">
                                            Soluciones Médicas del Pacífico<br> Boulevard Miguel de la Madrid Hurtado km. 13-1.<br> Centro Salagua, Manzanillo, Colima, México.<br>33 2550 7867
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style="padding-top:30px; border-top:1px solid #f1f0f0">
                                        <!--<table style="width: 100%;">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style="vertical-align:middle; border-radius: 3px; padding:30px; background-color: #f9f9f9; border-right: 5px solid white;">
                                                        <p
                                                            style="color:#303030; font-size: 14px;  line-height: 1.6; margin:0; padding:0;">
                                                            Latashia Nagy<br>100-148 Warwick Trfy, Kansas City, USA
                                                        </p>
                                                    </td>

                                                    <td
                                                        style="text-align: right; padding-top:0px; padding-bottom:0; vertical-align:middle; padding:30px; background-color: #f9f9f9; border-radius: 3px; border-left: 5px solid white;">
                                                        <p
                                                            style="color:#8f8f8f; font-size: 14px; padding: 0; line-height: 1.6; margin:0; ">
                                                            Invoice #: 741<br>
                                                            02.02.2019
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>--><!--En caso de factura, validar-->
                                        <table style="width: 100%; margin-top:40px;">
                                            <thead>
                                                <tr>
                                                    <th
                                                        style="text-align:center; font-size: 10px; color:#8f8f8f; padding-bottom: 15px;">
                                                        PRODUCTO
                                                    </th>
                                                    <th
                                                        style="text-align:center; font-size: 10px; color:#8f8f8f; padding-bottom: 15px;">
                                                        CANTIDAD
                                                    </th>
                                                    <th
                                                        style="text-align:right; font-size: 10px; color:#8f8f8f; padding-bottom: 15px;">
                                                        PRECIO
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in detallePedido.detalle">
                                                    <td style="padding-top:0px; padding-bottom:5px;">
                                                        <h4
                                                            style="font-size: 16px; line-height: 1; margin-bottom:0; color:#303030; font-weight:500; margin-top: 10px;">
                                                            {{item.producto.nombre}}</h4>
                                                    </td>
                                                    <td>
                                                        <p href="#"
                                                            style="font-size: 13px; text-decoration: none; line-height: 1; color:#909090; margin-top:0px; margin-bottom:0;">
                                                            {{item.cantidad}}</p>
                                                    </td>
                                                    <td style="padding-top:0px; padding-bottom:0; text-align: right;">
                                                        <p
                                                            style="font-size: 13px; line-height: 1; color:#303030; margin-bottom:0; margin-top:0; vertical-align:top; white-space:nowrap;">
                                                            $
                                                            {{item.producto.costo}}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table bgcolor="#ffffff" border="0" cellpadding="0" cellspacing="0"
                            style="position:relative; bottom:0; width:100%; background-color:#ffffff;border-collapse:separate !important; border-spacing:0;color:#242128; margin:0;padding:30px; padding-top: 20px;"
                            heigth="auto">
                            <tr>
                                <td colspan="3" style="border-top:1px solid #f1f0f0">&nbsp;</td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width: 100%">
                                    <p href="#"
                                        style="font-size: 13px; text-decoration: none; line-height: 1.6; color:#909090; margin-top:0px; margin-bottom:0; text-align: right;">
                                        Subtotal : </p>
                                </td>
                                <td style="padding-top:0px; text-align: right;">
                                    <p
                                        style="font-size: 13px; line-height: 1.6; color:#303030; margin-bottom:0; margin-top:0; vertical-align:top; white-space:nowrap; margin-left:15px">
                                        $
                                        {{detallePedido.importe}}</p>
                                </td>
                            </tr>
                            <!--<tr>
                                <td colspan="2" style="width: 100%">
                                    <p href="#"
                                        style="font-size: 13px; text-decoration: none; line-height: 1.6; color:#909090; margin-top:0px; margin-bottom:0; text-align: right;">
                                        Tax : </p>
                                </td>
                                <td style="padding-top:0px; text-align: right;">
                                    <p
                                        style="font-size: 13px; line-height: 1.6; color:#303030; margin-bottom:0; margin-top:0; vertical-align:top; white-space:nowrap; margin-left:15px">
                                        $
                                        2.18</p>
                                </td>
                            </tr>-->
                            <tr>
                                <td colspan="2" style="width: 100%">
                                    <p href="#"
                                        style="font-size: 13px; text-decoration: none; line-height: 1.6; color:#909090; margin-top:0px; margin-bottom:0; text-align: right;">
                                        Envío : </p>
                                </td>
                                <td style="padding-top:0px; text-align: right;">
                                    <p
                                        style="font-size: 13px; line-height: 1.6; color:#303030; margin-bottom:0; margin-top:0; vertical-align:top; white-space:nowrap; margin-left:15px">
                                        $100</p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width: 100%">
                                    <p href="#"
                                        style="font-size: 13px; text-decoration: none; line-height: 1.6; color:#909090; margin-top:0px; margin-bottom:0; text-align: right;">
                                        IVA : </p>
                                </td>
                                <td style="padding-top:0px; text-align: right;">
                                    <p
                                        style="font-size: 13px; line-height: 1.6; color:#303030; margin-bottom:0; margin-top:0; vertical-align:top; white-space:nowrap; margin-left:15px">
                                        {{detallePedido.importe * 0.16}}</p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style=" width: 100%; padding-bottom:15px;">
                                    <p href="#"
                                        style="font-size: 13px; text-decoration: none; line-height: 1.6; color:#909090; margin-top:0px; margin-bottom:0; text-align: right;">
                                        <strong>Total : </strong></p>
                                </td>
                                <td style="padding-top:0px; text-align: right; padding-bottom:15px;">
                                    <p
                                        style="font-size: 13px; line-height: 1.6; color:#303030; margin-bottom:0; margin-top:0; vertical-align:top; white-space:nowrap; margin-left:15px">
                                        <strong>${{detallePedido.total + 100}}</strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" style="border-top:1px solid #f1f0f0">&nbsp;</td>
                            </tr>
                            <tr>
                                <td colspan="3" style="text-align:center;">
                                    <p style="color: #909090; font-size:11px; text-align:center;"></p>
                                </td>
                            </tr>
                        </table>
                    </div>
                <!--</div>-->
            </div>

    		<p>En cuanto su pedido esté listo se le proporcionará el código de seguimiento del envío</p>
    		<h3>Si tienes alguna duda o necesitas más información respecto a tu compra contáctanos</h3>
    		<p><a href="https://api.whatsapp.com/send?phone=5213331015825"><img src="/static/img/WhatsApp_Logo_3.svg" id="whatsappLogo"> 33-3101-5825</a> o en nuestro <a href="/asistencia">Formulario de contacto</a></p>
    		<hr>
    		<img id="logoGracias" src="/static/img/logo-sole.svg">
    		<div class="mt-5">
    			<router-link class="btn btn-success" to="/plataforma/mapa">
    				Regresar
    			</router-link>
    		</div>
    	</section>
    	<section id="msgGracias" v-if="banderaPaso == 'suscripcion'">
    		<h1>¡Muchas gracias por su compra!</h1>
    		<p>La información de la suscripción será enviada a {{getUser.usuario}}</p>
    		<p>Será necesario acceder a la sección "Suscripciones" para elegir los dispositivos que aplican a esta renovación </p>
    		<h3>Si tienes alguna duda o necesitas más información respecto a tu compra contáctanos</h3>
    		<p><a href="https://api.whatsapp.com/send?phone=5213331015825"><img src="/static/img/WhatsApp_Logo_3.svg" id="whatsappLogo"> 33-3101-5825</a> o en nuestro <a href="/asistencia">Formulario de contacto</a></p>
    		<hr>
    		<img id="logoGracias" src="/static/img/logo-sole.svg">
    		<div class="mt-5">
    			<router-link class="btn btn-success" to="/plataforma/mapa">
    				Regresar
    			</router-link>
    		</div>
    	</section>
    <!--</b-card>-->
    </b-colxx>
</template>
<script>
import { request, mapActions} from 'vuex';


	export default{
        
		data(){
            return{
                selectedProducts:[],
                detallePedido:{}
            }
		},
        created(){
            this.getPedidoData();
        },
        methods: {
            ...mapActions(["getAll","getOne", "create", "update", "delete"]),

            getPedidoData: function(){
                let token = JSON.parse(localStorage.getItem('user')).token;
                var instance = this;
                let tokenCargo = new URLSearchParams(window.location.search);

                let payload = {
                    endpoint: "/api/pedido/"+ tokenCargo.get("id") + "/cargo",
                    token: token
                };

                this.getOne(payload).then(function(response){
                    //instance
                    instance.detallePedido = response.data.data;
                    
                }).catch(function(error){
                    console.log(error);
                });
            }
        }
	}

</script>
<style>
#msgGracias{
    padding: 2em;
    border: 2px solid rgba(72, 189, 19, 0.7);
    background-color: rgba(72,189,19,0.1);
    text-align: center;
    font-size: 1.5em;
}

#whatsappLogo{
    width: 30px;
}

#logoGracias{
    width: 120px;
}

button.addDirection{
    width: 100%;
}

.openpaylogo{
    text-align: left;
}

.openpaylogo img{
    width: 200px;
}

#logoVisaPlaceholder{
    width: 70px;
    position: absolute;
    top: 3px;
    right: 7px;
}

.txt-right{
    text-align: right;
}

.eliminarDireccion{
    color: black;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    font-size: 17pt;
    cursor: pointer;
}

.imgCell{
    padding-bottom: 35px; 
    padding-top: 15px; 
    border-top: 0;
    width: 100% !important;
}

.invoice{
    justify-content: center;
}

.invoice .invoice-contents{
    padding: 0px;
    height: auto;
    overflow: scroll;
}

.tableStyles{
    width: 100%; 
    background-color: #ffffff;
    border-collapse: separate !important;
    border-spacing: 0;
    color: #242128;
    margin: 0;
    padding: 30px;
}

.headerInvoice{
    color: #8f8f8f;
    font-weight: normal;
    line-height: 1.2;
    font-size: 12px;
    white-space: nowrap; 
}
</style>